import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import {
  Button,
  Form,
  Input,
  message,
  Select,
  Switch,
  Upload,
  UploadProps,
} from 'antd';
import type { RcFile } from 'antd/es/upload/interface';
import moment from 'moment';

// Config
import COUNTRIES from 'config/countries.json';

// Components
import Image from 'components/Image';

// Store
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'store/hooks';
import {
  getUserDetail,
  removeUserAvatar,
  updateUser,
  uploadUserAvatar,
} from 'store/global/action';

// Helpers
import {
  convertBase64ToFile,
  getBase64,
  getLevel,
  getUniqueKey,
  isBase64Image,
} from 'helpers/common.helper';

// Enums
import { EBeamReception } from 'enums/common.enum';

import './UserDetail.scss';

const { Option } = Select;

const UserDetail = () => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const params = useParams();

  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<any>(null);

  const fetchData = async (id: string) => {
    try {
      const res: any = await unwrapResult(await dispatch(getUserDetail(id)));
      console.log(res);
      setData(res);
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = async (values: any) => {
    const valid = await form.validateFields();
    if (!valid) return;

    let data: any = {
      fullName: values.fullName,
      phoneNumber: values.phoneNumber,
      countryCode: values.countryCode,
      country: values.country,
      status: values.status,
      beamReception: values.beamReception
        ? EBeamReception.Enable
        : EBeamReception.Disable,
    };

    try {
      setLoading(true);
      await handleUploadAvatar();
      await unwrapResult(
        await dispatch(
          updateUser({
            id: params.id,
            values: data,
          })
        )
      );
      message.success('Updated user successfully');
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const uploadAvatarProps: UploadProps = {
    beforeUpload: (file: any) => {
      getBase64(file as RcFile, (data) => {
        setAvatar(data);
      });
      return false;
    },
    showUploadList: false,
  };

  const clearAvatar = () => {
    setAvatar(null);
  };

  const handleUploadAvatar = async () => {
    if (!avatar) {
      return unwrapResult(await dispatch(removeUserAvatar(params.id!)));
    }

    if (avatar && isBase64Image(avatar)) {
      const file = await convertBase64ToFile(avatar);
      return await unwrapResult(
        await dispatch(
          uploadUserAvatar({
            id: params.id,
            file,
          })
        )
      );
    }
  };

  useEffect(() => {
    if (params && params.id) {
      fetchData(params.id);
    }
  }, [params]);

  useEffect(() => {
    if (data && data.thumbnail) {
      setAvatar(data.thumbnail.medium);
    }
  }, [data]);

  return (
    <>
      {data ? (
        <div className="content user-detail-content">
          <div className="user-detail-content__avatar">
            <Image
              styles={{
                width: '150px',
                height: '150px',
                borderRadius: '50%',
              }}
              source={avatar ? avatar : require('assets/img/avatar.png')}
            />

            <div className="user-detail-content__avatar__actions">
              <Upload {...uploadAvatarProps}>
                <Button className="btn btn-primary">Browse</Button>
              </Upload>

              <Button className="btn btn-danger" onClick={clearAvatar}>
                Delete
              </Button>
            </div>
          </div>

          <div className="user-detail-content__form">
            <Form
              name="basic"
              onFinish={onSubmit}
              autoComplete="off"
              layout="vertical"
              className="default"
              form={form}
              initialValues={data}
            >
              <Row>
                <Col md="6">
                  <Form.Item
                    label="Full Name"
                    name="fullName"
                    rules={[
                      { required: true, message: 'Full name is required' },
                      {
                        max: 100,
                        message:
                          'Full name must be no more than 100 characters',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col md="6">
                  <div className="static-form-item">
                    <label>Email</label>
                    <Input value={data.email} disabled />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <Form.Item
                    label="Status"
                    name="status"
                    rules={[
                      {
                        max: 500,
                        message: 'Status must be no more than 500 characters',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col md="6">
                  <div className="static-form-item">
                    <label>Level</label>
                    <Input value={getLevel(data.beamTimes)} disabled />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="static-form-item">
                    <label>Phone Number</label>

                    <Row>
                      <Col md="6">
                        <Form.Item name="countryCode">
                          <Select className="default">
                            {COUNTRIES.data.map((item, index) => (
                              <Option key={getUniqueKey()} value={item.code}>
                                {item.emoji} {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col md="6">
                        <Form.Item
                          name="phoneNumber"
                          rules={[
                            {
                              max: 100,
                              message:
                                'Phone number must be no more than 100 characters',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="6">
                  <Form.Item label="Country" name="country">
                    <Select className="default">
                      {COUNTRIES.data.map((item, index) => (
                        <Option key={getUniqueKey()} value={item.code}>
                          {item.emoji} {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <Form.Item
                    label="Beam Reception"
                    name="beamReception"
                    valuePropName="checked"
                  >
                    <Switch className="default" />
                  </Form.Item>
                </Col>

                <Col md="6">
                  <div className="static-form-item">
                    <label>Created At</label>
                    <Input
                      value={moment(data.createdAt).format('YYYY-MM-DD')}
                      disabled
                    />
                  </div>
                </Col>
              </Row>

              <Form.Item>
                <Button
                  className="btn btn-primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default UserDetail;
