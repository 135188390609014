import DemoNavbar from 'components/Navbars';
import Sidebar from 'components/Sidebar';
import { Global } from 'hocs/global.hoc';
import { Private } from 'hocs/private.hoc';
import TypeDetail from 'pages/TypeDetail';
import UserDetail from 'pages/UserDetail';
import PerfectScrollbar from 'perfect-scrollbar';
import React, { useEffect, useRef } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import routes from 'routes';

let ps: any;

const Dashboard = (props: any) => {
  const mainPanel = useRef(null);

  const location = useLocation();

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current!);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  });

  useEffect(() => {
    // mainPanel.current.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;
  }, [location]);

  return (
    <div className="wrapper">
      <Sidebar {...props} routes={routes} bgColor="black" activeColor="info" />

      <div className="main-panel" ref={mainPanel}>
        <DemoNavbar {...props} />

        <Routes>
          <Route path={'user/:id'} element={<UserDetail />} />
          <Route path={'type/:id'} element={<TypeDetail />} />
          {routes.map((prop: any, key: any) => {
            return (
              <Route path={prop.path} element={prop.component} key={key} />
            );
          })}
        </Routes>
      </div>
    </div>
  );
};

export default Global(Private(Dashboard));
