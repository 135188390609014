import { removeToken } from 'helpers/common.helper';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Collapse,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarToggler,
} from 'reactstrap';
import { storeMe } from 'store/global/action';
import { useAppDispatch } from 'store/hooks';
import routes from '../../routes';

const Header = (props: any) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [color, setColor] = React.useState('transparent');
  const sidebarToggle = React.useRef(null);
  const location = useLocation();

  const toggle = () => {
    if (isOpen) {
      setColor('transparent');
    } else {
      setColor('dark');
    }
    setIsOpen(!isOpen);
  };

  const dropdownToggle = (e: any) => {
    setDropdownOpen(!dropdownOpen);
  };

  const getBrand = () => {
    let brandName = '';

    routes.map((item) => {
      if (window.location.href.indexOf(item.path) !== -1) {
        brandName = item.name;
      }
      return null;
    });

    return brandName;
  };

  const openSidebar = () => {};

  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor('dark');
    } else {
      setColor('transparent');
    }
  };

  const handleLogOut = () => {
    dispatch(storeMe(undefined));
    removeToken();
    navigate('/login');
  };

  useEffect(() => {
    window.addEventListener('resize', updateColor.bind(this));
  });

  return (
    <Navbar
      color={
        location.pathname.indexOf('full-screen-maps') !== -1 ? 'dark' : color
      }
      expand="lg"
      className={
        location.pathname.indexOf('full-screen-maps') !== -1
          ? 'navbar-absolute fixed-top'
          : 'navbar-absolute fixed-top ' +
            (color === 'transparent' ? 'navbar-transparent ' : '')
      }
    >
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button
              type="button"
              ref={sidebarToggle}
              className="navbar-toggler"
              onClick={toggle}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>

          <NavbarBrand href="/">{getBrand()}</NavbarBrand>
        </div>

        <NavbarToggler onClick={toggle} className="d-none d-lg-block">
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </NavbarToggler>

        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          <div className="d-none d-lg-block">
            <Nav navbar>
              <Dropdown
                nav
                isOpen={dropdownOpen}
                toggle={(e: any) => dropdownToggle(e)}
              >
                <DropdownToggle caret nav>
                  <i className="fas fa-user"></i>
                </DropdownToggle>

                <DropdownMenu right>
                  <DropdownItem onClick={handleLogOut} tag="a">
                    Log Out
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </div>

          <div className="d-lg-none">
            <Nav navbar>
              {routes.map((item, index) => (
                <NavItem key={index}>
                  <NavLink href={item.path}>
                    <i className={item.icon} />
                    <span>{item.name}</span>
                  </NavLink>
                </NavItem>
              ))}

              <NavItem>
                <NavLink onClick={handleLogOut}>
                  <span>Log Out</span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
